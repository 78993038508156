<template>
  <div>
    <b-button
      type="button"
      variant="info"
      class="ml-3"
      @click="getContract()"
      :disabled="isLoading"
    >
      <span v-if="!isLoading">Зээлийн гэрээ</span>
      <span v-else>Уншиж байна</span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "contract-loan",
  data: function() {
    return {
      isLoading: false,
    };
  },
  props: {
    selectCustomer: { type: [Array, Object], default: () => [] },
    coCustomer: { type: [Array, Object], default: () => [] },
    deposit_women: { type: [Array, Object], default: () => [] },
    contract_number: { type: String, default: "" },
    amount: { type: Number, default: 0 },
    interest: { type: Number, default: 0 },
    term: { type: Number, default: 1 },
  },
  methods: {
    getContract: function() {
      this.$data.isLoading = true;
      let request_data = {
        contractNumber: this.contract_number,
        customerId: this.selectCustomer.id,
        coCustomerId: null,
        loanAmount: this.amount,
        loanInterest: this.interest,
        loanTerm: this.term,
        spec: this.deposit_women.spec,
        operatedSince: this.deposit_women.operatedSince,
        operation: this.deposit_women.operation,
        operatingLocation: this.deposit_women.operatingLocation,
        operatingLocationType: this.deposit_women.operatingLocationType,
        savingBank: this.deposit_women.savingBank,
        savingBankAccount: this.deposit_women.savingBankAccount
      };
      console.log(request_data)
      if (this.coCustomer) {
        request_data["coCustomerId"] = this.coCustomer.id;
      }

      console.log(this.deposit_women)

      this.$http
        .post(
          this.$config.API_URL +
            "ContractWebService/small_business_loan_contract",
          {
            data: JSON.stringify(request_data),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response);
            this.$data.isLoading = false;
            if (response.body.successData) {
              console.log(response.body.successData)
              window.open(response.body.successData, "_blank");
            } else {
              this.showToast("Анхаар", "Алдаа гарсан байна", "warning");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>